<template>
    <div class="section-wrap">
        <StepHeader :stepDetails=stepHeaderConfig ref="stepHeader"></StepHeader>
        <v-container class="form">
            <v-row justify="center">
                <v-col cols="12">
                    <v-card elevation="2" class="rounded-tr-0 rounded-tl-0 spacing-playground">
                        <v-row justify="center">
                            <v-col cols="12" md="6">
                                <v-card-text>
                                    <ContactInfo ref="contactInfo" @focusHeader="focusHeader"></ContactInfo>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-footer ref="controlZoomLevel" app color="white" elevation="2">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="auto">
                    <v-btn @click="goBackToPricing">{{ previousButtonText }}</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" tabindex="0" @click="submitContactInfo">
                        {{ nextButtonText }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>
<script>
    import StepHeader from '../../components/shared/StepHeader.vue';
    import ContactInfo from '../../components/global/ContactInfo.vue';
    import customerDetails from '../Global/JS/customerDetails.js';

    export default {
        mixins: [customerDetails],
        components: { StepHeader, ContactInfo },
        data: () => ({
            stepHeaderConfig: [],
        }),
        created() {
            this.stepHeaderConfig = {
                stepHeading: "Contact Information",
                currentStep: 3,
                totalSteps: 5
            };
        },
        beforeMount() {
            this.nextButtonText = "Go to: Payment";
        },
        mounted() {
            window.liQ = window.liQ || [];
            
            // Optimization: removing QuantumServe.com Call 
            // window._qevents = window._qevents || [];
            // (function() {
            //     var elem = document.createElement('script');
            //     elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            //     elem.async = true;
            //     elem.type = "text/javascript";
            //     var scpt = document.getElementsByTagName('script')[0];
            //     scpt.parentNode.insertBefore(elem, scpt);
            // })();
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.PageView",
            //     "event": "refresh",
            //     "uid": this.$store.getters.owner.email
            // });
            
            // window._qevents = window._qevents || [];
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.Pet Insurance Quote: Step 3",
            //     "event": "refresh"
            // });
        },
        methods: {
            focusHeader() {
                this.$nextTick(() => this.$refs.stepHeader.focus());
            },
            goBackToPricing() {
                this.$store.commit("setCurrentPage","Plan Selection");
                this.redirectToView("quotes");
            },
            async submitContactInfo() {
                if(await this.$refs.contactInfo.submitContactInfo()){
                    this.$store.commit("setStepComplete", "billing");
                    this.$store.commit("setStepActive", "payment");

                    this.$store.commit("setCurrentPage","Payment Info");

                    this.redirectToView("payment");
                }
            },
            redirectToView(step) {
                this.routerPush(step);
            }
        }
    }
</script>